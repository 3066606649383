// Import Image for Banner Component
import BannerImage from '../../../assets/Images/image-skilsmisse.png';

// Import Image for ImageCard_Collapse
import nytjob from '../../../assets/Images/InfoCard1.png';

// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Page Data
const Page3data = {
  pageName: 'Page3',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_3',
    pageTitle: 'Sampension',
    pageDescription: 'Sampensions web site',
    bannerImage: null,
    bannerContent: '<h1>banner overskrift</h1><p>banner manchet</p>',
    bottomContent: [
      {
        componentName: 'BlockArea',
        componentData: {
          backgroundColor: ColorGuide.aqua,
        },
        componentChildren: [
          {
            componentName: 'HtmlRender',
            componentData: {
              html: '<h2 class="text-center">Andre har også læst</h2>',
            },
          },
          {
            componentName: 'Columns',
            componentData: {
              columns: 3,
              gap: '40',
            },
            componentChildren: [
              {
                componentName: 'InfoCard',
                componentData: {
                  id: 1,
                  image: nytjob,
                  category: 'pension',
                  text:
                    '<h3>Få overblik over din pension</h3>Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
                  url: '/item1',
                },
              },
              {
                componentName: 'InfoCard',
                componentData: {
                  id: 1,
                  image: null,
                  category: 'pension',
                  text:
                    '<h3>Få overblik over din pension</h3>Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
                  url: '/item1',
                },
              },
              {
                componentName: 'InfoCard',
                componentData: {
                  id: 1,
                  image: nytjob,
                  category: 'pension',
                  text:
                    '<h3>Få overblik over din pension</h3>Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension',
                  url: '/item1',
                },
              },
            ],
          },
        ],
      },
    ],
    desktopTools: null,
  },
  pageContent: [
    // Inside Banner
    {
      componentName: 'Banner',
      componentData: {
        image: BannerImage,
        header: 'Banner overskrift',
        manchet: 'Banner manchet',
        url: '/test',
        urlText: 'Læs mere',
        fullWidth: true,
      },
    },

    // HTML Render
    {
      componentName: 'HtmlRender',
      componentData: {
        html:
          '<center><h2>Begunstigelse - Hvem skal have udbetaling ved din død?</h2></center>'
          + '<p class="manchet">Med en begunstigelse vælger du selv, hvem der skal modtage udbetaling fra din pensionsordning ved din død. Indsætter du ikke selv en begunstiget, udbetaler vi automatisk til dine nærmeste pårørende.</p><p>Da din pensionsordning blev oprettet i Sampension blev dine begunstigede automatisk sat til at være ”dine nærmeste pårørende”. </p>'
          + '<p>Du kan også selv tage stilling til, hvem du vil begunstige. Det gør du ved at udfylde en begunstigelseserklæring. I erklæringen angiver du, hvor stor en del af udbetalingen vedkommende skal tilgodeses med. Du kan begunstige en eller flere personer.</p>'
          + '<ul><li>bullet 1</li><li>bullet 2</li><li>bullet 3</li><li>bullet 4</li><li>bullet 5</li><li>bullet 6</li></ul>'
          + '<ol><li>bullet 1</li><li>bullet 2</li><li>bullet 3</li><li>bullet 4</li><li>bullet 5</li><li>bullet 6</li>'
          + '<li>bullet 1</li><li>bullet 2</li><li>bullet 3</li><li>bullet 4</li><li>bullet 5</li><li>bullet 6</li></ol>'
          + '<h3>Hvornår skal jeg ændre min begunstigelse?</h3>'
          + '<p>Du bør jævnligt sikre dig, at begunstigelsen passer til dig og dine forhold. Du skal især overveje at ændre på begunstigelsen hvis du bliver gift eller skilt, får en samlever eller får børn, der sker større ændringer i din økonomi eller en af dine begunstigede dør. Det afhænger af din pensionsordning, hvilke udbetalinger du kan tilgodese dine efterladte med ved din død.</p>'
          + '<p class="text-center"><a href="/page2" class="primaryBtn">Udfyld erklæringen om begunstigelse</a></p>',
      },
    },

    // Collapsible Text Area
    {
      componentName: 'CollapsibleTextArea',
      componentData: {
        id: '5-qwerty',
        openText: 'Læs mere',
        closeText: 'Læs mindre',
        text: [
          {
            componentName: 'HtmlRender',
            componentData: {
              html:
                '<h3>Header Text</h3><p>Eget nunc scelerisque viverra mauris in aliquam. Massa ultricies mi quis hendrerit. Enim nulla aliquet porttitor lacus. Neque laoreet suspendisse interdum consectetur libero id. Sapien pellentesque habitant morbi tristique senectus et netus. Aenean euismod elementum nisi quis eleifend. Eget duis at tellus at urna condimentum. Orci porta non pulvinar neque laoreet suspendisse. Pellentesque nec nam aliquam sem et. Elit duis tristique sollicitudin nibh sit amet commodo. Mi proin sed libero enim sed faucibus turpis in.</p>',
            },
          },
        ],
      },
      componentChildren: [
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet consectetur adipiscing elit duis tristique sollicitudin nibh sit. Auctor urna nunc id cursus metus. Mollis nunc sed id semper risus. Adipiscing at in tellus integer feugiat. Mauris cursus mattis molestie a iaculis. Magna ac placerat vestibulum lectus mauris ultrices eros. Sed cras ornare arcu dui vivamus arcu felis bibendum. Nibh praesent tristique magna sit amet purus gravida quis. In ornare quam viverra orci sagittis eu volutpat. Id donec ultrices tincidunt arcu non. In aliquam sem fringilla ut. In ante metus dictum at tempor commodo ullamcorper a. Turpis in eu mi bibendum. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Non nisi est sit amet facilisis magna etiam tempor orci.</p>',
          },
        },
      ],
    },

    // Collapsable FAQ
    {
      componentName: 'CollapseableFAQ',
      componentData: {
        header: 'FAQ / Pension og skilsmisse',
        faqs: [
          {
            id: '1',
            header: 'Hvad sker der med mine pensioner, hvis jeg skal skilles?',
            text:
              'Reglerne ved separation og skilsmisse er, at du altid kan beholde "rimelige pensioner".<br /><br />Almindelige arbejdsmarkedspensioner er altid rimelige, eftersom du kun betaler det beløb, der fx er fastsat i din lønoverenskomst. Som hovedregel skal værdien af din pension i Sampension ikke deles. Men din ægtefælle skal måske have en kompensation.',
          },
          {
            id: '2',
            header: 'Kan jeg beholde mine pensioner, hvis jeg skal skilles?',
            text: 'text 2',
          },
          {
            id: '3',
            header: 'Hvad sker der med ægtefællepensionen ved skilsmisse?',
            text: 'text 3',
          },
        ],
      },
    },

    // TextArea
    {
      componentName: 'TextArea',
      componentData: {
        color: ColorGuide.lightGray,
      },
      componentChildren: [
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<h2>Ikke alle er dækket</h2><p>En mindre gruppe kunder er ikek dækket ved sygdom - det gælder blandt andet de fleste kunder ansat i fleksjob elle r under andre særlige nsættelsesforhold.</p>',
          },
        },
      ],
    },
  ],
};

// Exports
export default Page3data;
