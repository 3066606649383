// Import Node Modules
import React from 'react';

// renderPage
import { renderPage } from '../template/RenderPage';

// TempData
import Page3data from '../mocks/pages/PageData/page3';

// Class
const Page3 = () => <div>{renderPage(Page3data)}</div>;

export default Page3;
